import React from 'react';
import Pagination from '@mui/material/Pagination';
import '../../../App.css';

function Footer({
  downloadXLSXData,
  handleFilter,
  handleRefresh,
  isFiltering,
  totalItems,
  currentPage,
  itemsPerPage,
  setCurrentPage,
}) {
  return (
    <div className='border-top d-flex justify-content-between align-items-center mt-5 p-1 footer'>
      <div className='result-spacing'>Showing {totalItems} results </div>
      <div className="d-flex justify-content-center">
        <Pagination
          count={Math.ceil(totalItems / itemsPerPage)}
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
          size="small"
        />
      </div>
      <div className='d-flex'>
        {/* <div className="custom-icon-spacing" onClick={handleFilter}>
            <i className={`fa ${isFiltering ? 'fa-sort-alpha-asc' : 'fa-sort-alpha-desc'}`} aria-hidden="true"></i>
        </div> */}
        <div className="custom-icon-spacing" onClick={downloadXLSXData}>
        <i className="fa fa-download" aria-hidden="true"></i>    
        </div>
        <div className="custom-icon-spacing" onClick={handleRefresh}>         
        <i className="fa fa-undo" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  );
}

export default Footer;
