import React, { useState } from 'react'
import { Button, Container, Paper, TextField, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import appoinentImg from "../../../assets/img/appointment.webp";

function BusinessNumber() {
    const navigate = useNavigate();
    const [businessNo, setBusinessNo] = useState("");
    const [countryCode, setCountryCode] = useState("");

    const businessNoHandler = () => {
        navigate("/");
        localStorage.setItem("business number", businessNo); 
        localStorage.setItem("country code", countryCode);
    }

    // const calenderHandler = async() => {
    //     try{
    //         const data = await axios.get("https://whatsapp-node-api.azurewebsites.net/auth");
    //         console.log("data from BusinessNumber" , data.data);
    //         console.log("data from BusinessNumber" , data.authUrl);
    //         window.location.href = data.data;
    //     }
    //     catch(err){
    //         console.log("err from BusinessNumber" , err.message);
    //     }

    // }
 
    return (
        <div
            style={{
                position: "relative",
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    backgroundImage: `url(${appoinentImg})`,
                    position: "absolute",
                    top: "-70px",
                    left: "-5px",
                    width: "100%",
                    height: "100%",
                    filter: "blur(5px)",
                }}
            />
            <Container maxWidth="sm" style={{ position: "relative", zIndex: 1, top: "-70px" }}>
                <Paper elevation={3} sx={{ padding: 3 }}>
                    <Typography variant="h5" gutterBottom align="center">
                        Business Number
                    </Typography>
                    <TextField
                        label="Country Code"
                        type="text"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        // color="success"
                        onChange={(e) => setCountryCode(e.target.value)}
                    />
                    <TextField
                        label="Business Number"
                        type="text"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        // color="success"
                        onChange={(e) => setBusinessNo(e.target.value)}
                    />
                     {/* <Button
                        variant="contained"
                        // color="success"
                        fullWidth
                        onClick={calenderHandler}
                        sx={{ marginTop: 2 }}
                    >
                      Calender Permission
                    </Button> */}
                    <Button
                        variant="contained"
                        // color="success"
                        fullWidth
                        onClick={businessNoHandler}
                        sx={{ marginTop: 2 }}
                    >
                        Click Here
                    </Button>
                   
                </Paper>
            </Container>
        </div>
    )
}

export default BusinessNumber;
