import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import axios from 'axios';
import document from "../../../assets/document/instruction.xlsx"
import * as XLSX from 'xlsx';

const AddInBulk = ({ guards, getGuards, setOpenAddBulkModal, openAddBulkModal }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState("");
    const [fileContent, setFileContent] = useState(null); 

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        // Read file content
        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result;

            // Check if it's an Excel file
            if (file.name.endsWith('.xlsx')) {
                const workbook = XLSX.read(content, { type: 'array' });
                const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
                setFileContent(jsonData);
                // console.log('File content (JSON):', jsonData);
            } else {
                setFileContent(content);
                // console.log('File content:', content);
            }
        };
        reader.readAsArrayBuffer(file);
    };

    const handleAddBulkData = async () => {
        try {
            const hasEmptyField = fileContent.some(item => (
                !item.srNo || !item.branchLatLong || !item.nameOfGuard || !item.guardWhatsapp || !item.aoName || !item.aoWhatsapp
            ));
            
            if (hasEmptyField) {
                setError("One or more required fields are empty in the uploaded data.");
                return;
            }

            const guardsWhatsappArray = guards.map(guard => parseInt(guard.guardWhatsapp));
            const fileContentWhatsappArray = fileContent.map(item => item.guardWhatsapp);
            const commonNumbers = guardsWhatsappArray.filter(number => fileContentWhatsappArray.includes(number));
            const hasDuplicateWhatsapp = fileContentWhatsappArray.some(whatsapp => guardsWhatsappArray.includes(whatsapp));
            
            // console.log("hasDuplicateWhatsapp", hasDuplicateWhatsapp);
            // console.log("guardsWhatsappArray",guardsWhatsappArray)
            // console.log("commonNumbers",commonNumbers)
            // console.log("fileContentWhatsappArray",fileContentWhatsappArray)

            if (hasDuplicateWhatsapp) {
                setError(`${commonNumbers.join(', ')} - This WhatsApp numbers is/are already present.`);
                return;
            }
            const formData = new FormData();
            formData.append('file', selectedFile);

            const localToken = localStorage.getItem("auth_token");
            const config = {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                },
            };

            // Add your API endpoint for uploading bulk data
            await axios.post('https://guards-apis.onrender.com/api/guards/bulk-excel', formData, config);

            // Refresh the Guards data after uploading
            getGuards();
            setOpenAddBulkModal(false);

        } catch (error) {
            setError("An error occurred while processing your request");
        }
        window.location.reload();
    };

    return (
        <Dialog open={openAddBulkModal} onClose={() => setOpenAddBulkModal(false)}>
            <DialogTitle style={{
                display: "flex",
                justifyContent: "center", color: "white", backgroundColor: "#154d32",
                marginBottom: "25px"
            }}>
                Bulk Data Upload
            </DialogTitle>
            <DialogContent>
                <p>
                    Follow the instructions below to upload bulk data:
                </p>
                <ol>
                    <li>
                        Download the template Excel file:{' '}
                        <a href={document} download>
                            Download Template
                        </a>
                    </li>
                    <li>Fill in all required fields in the downloaded template.</li>
                    <li>Click the "Upload" button to add the data in bulk.</li>
                </ol>
                <input type="file" onChange={handleFileChange} />
            </DialogContent>
            {error && <Typography color="error" style={{ display: "flex",  marginLeft: "40px" , marginRight:"20px"}}>{error}</Typography>}
            <DialogActions>
                <Button onClick={handleAddBulkData}
                    style={{ color: "white", backgroundColor: "#154d32" }}>
                    Upload
                </Button>
                <Button onClick={() => setOpenAddBulkModal(false)}
                    style={{ color: "white", backgroundColor: "#154d32" }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddInBulk;
