import React, { useState } from 'react';
import { Button, Container, Paper, TextField, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import bgImg from "../../../assets/img/bgImg.jpg";

function AoFilterPage() {
    const [aoName, setAoName] = useState("");
    const [aoMobile, setAoMobile] = useState("");
    const navigate = useNavigate();

    const aoFilterHandler = () => {
        if(!aoName && !aoMobile){
            alert("Please fill in Ao Name & Ao Mobile Number.");
        } else if (!aoName) {
            alert("Please fill in Ao Name.");
        } else if (!aoMobile) {
            alert("Please fill in Ao Mobile Number.");
        } else if (isNaN(aoMobile) || aoMobile.length !== 10) {
            alert("Please enter a valid 10-digit Ao Mobile Number.");
        } else {
            navigate("/",{ state: { aoName, aoMobile } });
        }
    };

    return (
        <div
            style={{
                position: "relative",
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    backgroundImage: `url(${bgImg})`,
                    position: "absolute",
                    top: "-70px",
                    left: "-5px",
                    width: "100%",
                    height: "100%",
                    filter: "blur(5px)",
                }}
            />
            <Container maxWidth="sm" style={{ position: "relative", zIndex: 1 , top: "-70px"}}>
                <Paper elevation={3} sx={{ padding: 3 }}>
                    <Typography variant="h5" gutterBottom align="center">
                        Ao Information
                    </Typography>
                    <TextField
                        label="Ao Name"
                        type="text"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        color="success"
                        onChange={(e) => setAoName(e.target.value)}
                    />
                    <TextField
                        label="Ao Mobile Number"
                        fullWidth
                        margin="normal"
                        type="text"
                        variant="outlined"
                        color="success"
                        onChange={(e) => setAoMobile(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        onClick={aoFilterHandler}
                        sx={{ marginTop: 2 }}
                    >
                        Click Here
                    </Button>
                </Paper>
            </Container>
        </div>
    );
}

export default AoFilterPage;
