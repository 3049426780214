import React, { useState } from "react";
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import axios from "axios";
import { useUser } from '../../../App';

function AddRow({ guards, getGuards, setOpenAddModal, openAddModal }) {

  const user = useUser();
  const [newRow, setNewRow] = useState({
    srNo: "",
    zone: "",
    branchCode: "",
    branchName: "",
    branchLatLong: "",
    nameOfGuard: "",
    guardWhatsapp: "",
    guardAge: "",
    aoName: "",
    aoWhatsapp: "",
    vendorName: "",
  });

  const handleNewRowInputChange = (e, field) => {
    setNewRow((prevNewRow) => ({
      ...prevNewRow,
      [field]: e.target.value,
    }));
  };

  // Function to add the new row and close the modal
  const handleAddRow = async () => {
    // Validate new row data here if needed
    const guardAgeInt = parseInt(newRow.guardAge, 10);

    if (isNaN(guardAgeInt)) {
      alert("Guard Age must be a valid integer");
      return;
    }

    if (guardAgeInt > 150) {
      alert("Guard Age must be below 150 yrs ");
      return;
    }

    // Check if the WhatsApp number already exists in guards list
    const isGuardExists = guards.some((guard) => guard.guardWhatsapp === newRow.guardWhatsapp);

    if (isGuardExists) {
      const existingGuard = guards.find((guard) => guard.guardWhatsapp === newRow.guardWhatsapp);
      alert(`The WhatsApp number "${existingGuard.guardWhatsapp}" is already taken by "${existingGuard.nameOfGuard}".\n\n Please use a different number.`);
      return;
    }

    if (newRow.guardWhatsapp === "") {
      alert("Please add Guard's WhatsApp Number .");
      return;
    }

    try {
      const localToken = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      };

      await axios.post(
        "https://guards-apis.onrender.com/api/guards",
        {
          ...newRow,
          guardAge: guardAgeInt,
        },
        config
      );
      await getGuards();
      setOpenAddModal(false);
    } catch (error) {
      console.error("Error adding new row:", error.message);
      alert("Error adding new row. Please try again.");
      console.error(error.response ? error.response.data : error.message);
    }
  };

  return (
    <Dialog open={openAddModal} onClose={() => setOpenAddModal(false)}>
      <DialogTitle style={{
        display: "flex",
        justifyContent: "center", color: "white",
        backgroundColor: "#154d32",
        marginBottom: "25px"
      }}>
        Add New Row
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Sr No"
          variant="outlined"
          color="success"
          value={newRow.srNo}
          onChange={(e) => handleNewRowInputChange(e, "srNo")}
          style={{ margin: "5px" }}
        />
        <TextField
          label="Zone"
          variant="outlined"
          color="success"
          value={newRow.zone}
          onChange={(e) => handleNewRowInputChange(e, "zone")}
          style={{ margin: "5px" }}
        />
        <TextField
          label="Branch Code"
          variant="outlined"
          color="success"
          value={newRow.branchCode}
          onChange={(e) => handleNewRowInputChange(e, "branchCode")}
          style={{ margin: "5px" }}
        />
        <TextField
          label="Branch Name"
          variant="outlined"
          color="success"
          value={newRow.branchName}
          onChange={(e) => handleNewRowInputChange(e, "branchName")}
          style={{ margin: "5px" }}
        />
        <TextField
          label="Branch LatLong"
          variant="outlined"
          color="success"
          value={newRow.branchLatLong}
          onChange={(e) => handleNewRowInputChange(e, "branchLatLong")}
          style={{ margin: "5px" }}
        />
        <TextField
          label={user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Name of Seller" : "Name of Guard"}
          variant="outlined"
          color="success"
          value={newRow.nameOfGuard}
          onChange={(e) => handleNewRowInputChange(e, "nameOfGuard")}
          style={{ margin: "5px" }}
        />
        <TextField
          label={user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Sellers Age" :"Guards Age"}
          variant="outlined"
          color="success"
          value={newRow.guardAge}
          onChange={(e) => handleNewRowInputChange(e, "guardAge")}
          style={{ margin: "5px" }}
        />
        <TextField
          label={user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Sellers WhatsApp" :"Guards WhatsApp"}
          variant="outlined"
          color="success"
          value={newRow.guardWhatsapp}
          onChange={(e) => handleNewRowInputChange(e, "guardWhatsapp")}
          style={{ margin: "5px" }}
        />
        <TextField
          label={user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Shopkeeper Name" :"AoName"}
          variant="outlined"
          color="success"
          value={newRow.aoName}
          onChange={(e) => handleNewRowInputChange(e, "aoName")}
          style={{ margin: "5px" }}
        />
        <TextField
          label={user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Shopkeeper WhatsApp" :"AoWhatsApp"}
          variant="outlined"
          color="success"
          value={newRow.aoWhatsapp}
          onChange={(e) => handleNewRowInputChange(e, "aoWhatsapp")}
          style={{ margin: "5px" }}
        />
         <TextField
          label="Vendor Name"
          variant="outlined"
          color="success"
          value={newRow.vendorName}
          onChange={(e) => handleNewRowInputChange(e, "vendorName")}
          style={{ margin: "5px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenAddModal(false)}
          style={{ color: "white", backgroundColor: "#154d32" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleAddRow}
          style={{ color: "white", backgroundColor: "#154d32" }}
        >
          Add Row
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddRow;
