import React, { useState } from "react";
import { useUser } from "../../App";
import { useLocation, Link, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import icon from "../../assets/img/icon.png";
import dummyIcon from "../../assets/img/nsquaretest icon.png"
import appointmentBookingIcon from "../../assets/img/appointmentBooking.jpg"
import shopkeeper from "../../assets/img/shopkeeper.jpg"
import navImg from "../../assets/img/navImg.webp"

const navbarStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1,
};

function NavBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const location = useLocation();

  const navigate = useNavigate();
  const user = useUser();
  console.log("user from Nav", user);
  
  const pages = ["Home", "Guards"];
  const settings = ["Log Out", "Change Password"];

  const handleOpenChangePassword = () => {
    navigate("/change-password")
    handleCloseUserMenu();
  };
  const handleLogout = () => {
    localStorage.removeItem("auth_token");
    window.location.reload();
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const getOrganizationIcon = () => {
    if (user.organization.handle === "nsquaretest") {
      return dummyIcon;
    }
    if (user.organization.handle === "radiantguards") {
      return icon;
    }
    if (user.organization.handle === "appointmentbooking") {
      return appointmentBookingIcon;
    }
    if (user.organization.handle === "morangdesh") {
      return shopkeeper;
    }
  }

  return (
    <div style={navbarStyles}>
      <AppBar
        position="static"
        sx={{
          backgroundImage: user.organization.id === 'clsy51izu01wfhd270cihsj6n' ? `url(${navImg})` : 'none',
          backgroundSize: '35%',
          backgroundColor: user.organization.id === 'clsy51izu01wfhd270cihsj6n' ? "" : "#173d24",
          border: user.organization.id === 'clsy51izu01wfhd270cihsj6n' ? "2px solid #3487FD" : "2px solid #173d24"
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <Link
                    to={page === "Home" ? "/" : `/${page.toLowerCase()}`}
                    key={page}
                    style={{ textDecoration: "none" }}
                  >
                    <MenuItem
                      onClick={handleCloseNavMenu}
                      className="menu-item"
                    >
                      <Typography textAlign="center" sx={{ color: "#154d32" }}>
                        {page}
                      </Typography>
                    </MenuItem>
                  </Link>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
  (page === "Guards" && (user.email === "operations@radiantguards.com" || user.organization.id === 'clsy51izu01wfhd270cihsj6n' ||  user?.email == 'glcontrolroomthane@iifl.com')) ? null : (
    <Link
      to={page === "Home" ? "/" : `/${page.toLowerCase()}`}
      key={page}
      style={{ textDecoration: "none" }}
    >
      <Button
        sx={{
          my: 2,
          color: user.organization.id === 'clsy51izu01wfhd270cihsj6n' ? "black" : "white",
          fontWeight: user.organization.id === 'clsy51izu01wfhd270cihsj6n' ? "900" : "",
          display: "block",
          border:
            user.organization.id === 'clsy51izu01wfhd270cihsj6n'
              ? "3px solid #3487FD"
              : location.pathname === (page === "Home" ? "/" : `/${page.toLowerCase()}`)
                ? "3px solid #367d5b"
                : "transparent",
          borderRadius:
            location.pathname === (page === "Home" ? "/" : `/${page.toLowerCase()}`)
              ? "50px"
              : "transparent",
        }}
      >
        {user.organization.id === "clvafmvaz09mnmr1tifr2prjf" && page === "Guards" ? "Seller" : page} {/* Change "Guards" to "Seller" when organization ID matches */}
      </Button>
    </Link>
  )
))}

            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt="Remy Sharp"
                    src={getOrganizationIcon()}
                    className="circular-avatar"
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={setting === "Change Password" ? handleOpenChangePassword : handleCloseUserMenu}>
                    <Typography textAlign="center" onClick={setting === "Log Out" ? handleLogout : undefined}>
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
        {/* {changePasswordOpen && <ChangePassword onClose={handleCloseChangePassword} />} */}
      </AppBar>
    </div>
  );
}

export default NavBar;
