import React, { useState } from "react";
import { Button, Container, Paper, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useNavigate } from 'react-router-dom';
import LoginPage from "../Login/LoginPage";
import bgImg from "../../assets/img/bgImg.jpg";
import appoinentImg from "../../assets/img/appointment.webp"
import axios from "axios";
import { useUser } from '../../App';

const ChangePassword = () => {
  const navigate = useNavigate();
  const user = useUser();
  console.log("user from chanege password", user);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [backToLogin, setBackToLogin] = useState(false);

  const handlePassword = async () => {

    if (newPassword !== confirmPassword || newPassword.length < 8 || confirmPassword.length < 8) {
      setError("Please Enter valid details.");
    }
    else {
      try {
        const localToken = localStorage.getItem("auth_token");
        const config = {
          headers: {
            Authorization: `Bearer ${localToken}`,
          },
        };

        await axios.patch(
          "https://guards-apis.onrender.com/api/user/password",
          {
            currentPassword: currentPassword,
            newPassword: newPassword,
          },
          config
        );
        setIsLoading(true);
        localStorage.removeItem("auth_token");
        navigate('/');
        window.location.reload();
        setBackToLogin(true);
      } catch (error) {
        setError("Please Enter valid details.");
      }
    }
  };

  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh",
        minWidth: "100vw",
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundImage: user.organization.id === 'clsy51izu01wfhd270cihsj6n' ? `url(${appoinentImg})` :`url(${bgImg})`,
          position: "absolute",
          top: "-70px",
          left: "-5px",
          width: "100%",
          height: "100%",
          filter: "blur(5px)",
        }}
      />
      {isLoading && <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size="9rem" sx={{ color: "#154d32" }} />
      </Box>}
      {backToLogin ? (
        <LoginPage />
      ) : (
        <Container maxWidth="sm" sx={{ position: "relative", zIndex: 1, top: "-70px" }}>
          <Paper elevation={3} sx={{ padding: 3, backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
            <Typography variant="h5" gutterBottom align="center">
              Change Password
            </Typography>
            <TextField
              label="Current Password"
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              color={user.organization.id === 'clsy51izu01wfhd270cihsj6n' ? "" : "success"}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />

            <TextField
              label="New Password"
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              color={user.organization.id === 'clsy51izu01wfhd270cihsj6n' ? "" : "success"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              label="Confirm Password"
              type="password"
              fullWidth
              margin="normal"
              variant="outlined"
              color={user.organization.id === 'clsy51izu01wfhd270cihsj6n' ? "" : "success"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button
              variant="contained"
              color={user.organization.id === 'clsy51izu01wfhd270cihsj6n' ? "primary" : "success"}
              fullWidth
              sx={{ marginTop: 2 }}
              onClick={handlePassword}
            >
              Change Password
            </Button>
          </Paper>
        </Container>
      )}
    </div>
  );
};

export default ChangePassword;
