import ErrorLogo from "../../../assets/img/errorLogo.png";
import { useNavigate } from "react-router-dom";
import { useUser } from '../../../App';

const ErrorComponent = () => {
  const navigate = useNavigate();
  const user = useUser();

  const goToHomePage = () => {
    navigate("/");
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: "10em" }}>
      <img src={ErrorLogo} alt="error" style={{ height: "150px", width: "150px" }} />
      <h2>Oops! Something went wrong!</h2>
      {user?.email === "operations@radiantguards.com" ?
      <button className="btn btn-secondary mt-3" onClick={() => navigate("/aofilter")}>Go Back</button> :
      <button className="btn btn-secondary mt-3" onClick={goToHomePage}>Go to Home Page</button>}
    </div>
  );
};

export default ErrorComponent;
