import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
// import { useLocation } from 'react-router-dom';
// import { useUser } from '../../../App';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import Footer from "../Footer/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "font-awesome/css/font-awesome.min.css";
import appoinentImg from "../../../assets/img/appointmentBlur.jpg";
import io from 'socket.io-client'; 

function AppointmentBooking() {
  const itemsPerPage = 100;

  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalItems = filteredData.length;
  
  const businessNo = localStorage.getItem("business number");
  const CountryCode = localStorage.getItem("country code");

  const businessNoWithCountryCode = CountryCode + businessNo;
  console.log("businessNo2 from business page" , businessNoWithCountryCode);

  const getAppointmentData = async () => {
     try{
      setIsLoading(true);
      const resp = await axios.get(`https://whatsapp-wbhook.onrender.com/appointmentBooking/${businessNoWithCountryCode}`);
      console.log("resp from appointmentBooking" , resp.data.data);
      const appointments = resp.data.data.sort((a, b) => new Date(a.appointment_date) - new Date(b.appointment_date));
      console.log("appointments from appointmentBooking" , appointments);
      setFilteredData(appointments);
      console.log("filteredData from appointmentBooking" , filteredData);
      setIsLoading(false);
     }
     catch(err){
      console.log("error resp from appointmentBooking" , err.message);
     }
  }

  useEffect(()=>{
    getAppointmentData();
  },[])
  
  useEffect(() => {

    const socket = io('https://whatsapp-node-api.azurewebsites.net');

    socket.on('appointment', (message) => {
      console.log('Received message from server:');
      setTimeout(() => {
        getAppointmentData();
      }, 0)
    });

    // Cleanup when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []);
  const downloadXLSXData = async () => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Appointment Booking");
    worksheet.columns = [
      { header: "Sr No", key: "srNo" },
      { header: "Name", key: "name" },
      { header: "Email Id", key: "emailId" },
      { header: "Mobile Number", key: "mobileNumber" },
      { header: "Appointment Date ", key: "appointmentDate" },
      { header: "Appointment Time Slot ", key: "appointmentTime" }
    ];

    // Add data to the worksheet (filteredData is an array of objects)
    worksheet.addRows(
      filteredData.map((record,index) => ({
        srNo: index+1,
        name: record.name,
        emailId: record.email_id,
        mobileNumber: record.mobile_no,
        appointmentDate: new Date(record.appointment_date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' }),
        appointmentTime: record.appointment_timeSlot
      }))
    );

    // Generate the XLSX file as a Blob
    const blob = await workbook.xlsx.writeBuffer();

    // Create a Blob object and initiate the download
    const xlsxBlob = new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(xlsxBlob, "Appointment Booking.xlsx");
  };

  const handleRefresh = () => {
    getAppointmentData();
  };

  const handleFilter = () => {
    
  };

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size="9rem" sx={{ color: "#3487Fd" }} />
      </Box>
    );
  return (
    <div className="border border-light rounded ml-3 mr-4 shadow-md" >
      <div
        className="d-flex flex-column flex-lg-row ml-3"
        style={{ marginTop: "20px" }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 800 }} size="large" style={{  backgroundImage: `url(${appoinentImg})`}}>
            <TableHead>
              <TableRow style={{ whiteSpace: 'nowrap'}}>
                <TableCell style={{fontWeight:"900"}}>Sr No.</TableCell>
                <TableCell style={{fontWeight:"900"}}>Name</TableCell>
                <TableCell style={{fontWeight:"900"}}>Email ID</TableCell>
                <TableCell style={{fontWeight:"900"}}>Mobile Number</TableCell>
                <TableCell style={{fontWeight:"900"}}>Booking Date</TableCell>
                <TableCell style={{fontWeight:"900"}}>Appointment Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {filteredData.map((row, rowIndex) => {
                  return (
                    <TableRow
                      key={rowIndex} style={{ border : "1.5px solid #b9b9b9" , borderRadius:"5px"}}
                    >
                      <TableCell style={{ whiteSpace: 'nowrap' ,fontWeight:"500"}}>{rowIndex+1}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' ,fontWeight:"500"}}>{row.name}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' ,fontWeight:"500"}}>{row.email_id}</TableCell>
                      <TableCell style={{fontWeight:"500"}}>{row.mobile_no.substring(2)}</TableCell>
                      <TableCell style={{fontWeight:"500"}}>{new Date(row.appointment_date).toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' })}</TableCell>
                      <TableCell style={{fontWeight:"500"}}>{row.appointment_timeSlot}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div>
      <Footer
          downloadXLSXData={downloadXLSXData}
          handleFilter={handleFilter}
          handleRefresh={handleRefresh}
          isFiltering={isFiltering}
          totalItems={totalItems}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
        />
      </div>     
    </div>
  )
}

export default AppointmentBooking;