import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Footer from "../Footer/Footer";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteRow from "./DeleteRow";
import AddRow from "./AddRow";
import EditRow from "./EditRow";
import AddInBulk from "./AddInBulk";
import { TextField } from "@mui/material";
import { useUser } from '../../../App';

function Guards() {
  const itemsPerPage = 100;
  const user = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [guards, setGuards] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFiltering, setIsFiltering] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedGuard, setSelectedGuard] = useState(null);
  const [_editedGuard, setEditedGuard] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openAddBulkModal, setOpenAddBulkModal] = useState(false);
  const [branchCodeFilter, setBranchCodeFilter] = useState('');
  const [mobileNumberFilter, setMobileNumberFilter] = useState('');

  const columns = [
    { id: "#", label: "" },
    { id: "srNo", label: "Sr No" },
    {
      id: "zone",
      label: "Zone",
      format: (text) => (
        <span
          style={{
            color: "#ae692c",
            background: "#f1e3d8",
            padding: "3px 5px",
            borderRadius: "10px",
            fontWeight: "bold",
          }}
        >
          {text}
        </span>
      ),
    },
    { id: "branchCode", label: "Branch Code" },
    { id: "branchName", label: "Branch Name" },
    { id: "branchLatLong", label: "Lat Long" },
    { id: "nameOfGuard", label: user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Name of Seller" : "Name of Guard" },
    { id: "guardWhatsapp", label: user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Seller Mobile" : "Guard Mobile" },
    { id: "guardAge", label: user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Seller Age" : "Guard Age" },
    { id: "aoName", label: user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Shopkeeper Name" : "AO Name" },
    { id: "aoWhatsapp", label: user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? "Shopkeeper Mobile" : "AO Mobile" },
    {
      id: "vendorName",
      label: "Vendor Name",
      format: (text) => (
        <span
          style={{
            color: "#496a5b",
            background: "#d9ebe1",
            padding: "3px 7px",
            borderRadius: "10px",
            fontWeight: "bold",
          }}
        >
          {text}
        </span>
      ),
    },
  ];

  // Fetching Guards data
  const getGuards = useCallback(async () => {
    try {
      setIsLoading(true);

      const localToken = localStorage.getItem("auth_token");

      const config = {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      };

      const { data } = await axios.get(
        "https://guards-apis.onrender.com/api/guards",
        config
      );

      setIsLoading(false);
      setGuards(data);
    } catch (err) {
      console.error("error in .me: ", err, err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getGuards();
  }, [getGuards]);

  useEffect(() => {
    if (guards.length > 0) {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;

      const filteredData = guards.filter((guard) => {
        const branchCodeMatch = !branchCodeFilter || (guard.branchCode && guard.branchCode.includes(branchCodeFilter));
        const mobileNumberMatch = !mobileNumberFilter || (guard.guardWhatsapp && guard.guardWhatsapp.includes(mobileNumberFilter));

        return branchCodeMatch && mobileNumberMatch;
      });

      setFilteredData(filteredData.slice(startIndex, endIndex));
    }
  }, [guards, currentPage, branchCodeFilter, mobileNumberFilter]);

  const handleFilter = () => {
    // const sortedData = [...filteredData];
    // sortedData.sort((a, b) => {
    //   const nameA = a.nameOfGuard.toLowerCase();
    //   const nameB = b.nameOfGuard.toLowerCase();
    //   return isFiltering
    //     ? nameA.localeCompare(nameB)
    //     : nameB.localeCompare(nameA);
    // });
    // setFilteredData(sortedData);
    // setIsFiltering(!isFiltering);

    setFilteredData((prevFilteredData) => {
      const sortedData = [...prevFilteredData];
      sortedData.sort((a, b) => {
        const codeA = a.branchCode.toLowerCase();
        const codeB = b.branchCode.toLowerCase();
        return isFiltering ? codeA.localeCompare(codeB) : codeB.localeCompare(codeA);
      });
      return sortedData;
    });

    setIsFiltering(!isFiltering);
  };
  // For Refreshing the Page
  const handleRefresh = () => {
    setIsLoading(true);
    setTimeout(() => {
      const sortedData = [...guards].sort((a, b) => a.srNo - b.srNo);
      setGuards(sortedData);
      setIsLoading(false);
    }, 1000);
  };

  // For Downloading Data in Excel sheet
  const totalItems = guards.length;

  const downloadXLSXData = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("GuardData");
    worksheet.columns = columns.slice(1).map((column) => ({
      header: column.label,
      key: column.id,
    }));

    // Add all the data to the worksheet
    worksheet.addRows(
      guards.map((data) => columns.slice(1).map((column) => data[column.id]))
    );

    // Generate the XLSX file as a Blob
    const blob = await workbook.xlsx.writeBuffer();

    // Create a Blob object and initiate the download
    const xlsxBlob = new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(xlsxBlob, "GuardData.xlsx");
  };

  // For Deleting the data
  const isCheckboxSelected = (guard) => selectedCheckboxes.includes(guard);

  const handleCheckboxChange = (event, guard) => {
    const isChecked = event.target.checked;
    setSelectedCheckboxes((prevSelected) => {
      if (isChecked) {
        // Check if the guard is already in the array before adding
        if (
          !prevSelected.some(
            (selectedGuard) => selectedGuard.srNo === guard.srNo
          )
        ) {
          return [...prevSelected, guard];
        }
      } else {
        return prevSelected.filter(
          (selectedGuard) => selectedGuard.srNo !== guard.srNo
        );
      }
      return prevSelected;
    });
  };

  // For opening ADD NEW ROW modal
  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleOpenAddInBulkModal = () => {
    setOpenAddBulkModal(true);
  };

  const handleRowHover = (index) => {
    setHoveredRowIndex(index);
  };

  const handleEdit = (guard) => {
    setSelectedGuard(guard);
    setEditedGuard({ ...guard });
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => {
    setSelectedGuard(null);
    setEditedGuard(null);
    setOpenEditModal(false);
  };

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size="9rem" sx={{ color: "#154d32" }} />
      </Box>
    );

  return (
    <div className="shadow-sm">
      <div className="d-flex justify-content-between p-4">
        {user?.organization?.id === "clvafmvaz09mnmr1tifr2prjf" ? <h2>Seller</h2> : <h2>Guards</h2>}
        <div className="d-flex">
          <div style={{ marginRight: "40px" }}>
            <TextField id="outlined-basic"
              label="Search by Branch Code"
              variant="outlined"
              color="success"
              value={branchCodeFilter}
              onChange={(e) => setBranchCodeFilter(e.target.value)}
            />
          </div>
          <div>
            <TextField id="outlined-basic"
              label="Search by Mobile Number"
              variant="outlined"
              color="success"
              value={mobileNumberFilter}
              onChange={(e) => setMobileNumberFilter(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">
          {selectedCheckboxes.length > 0 &&
            (<DeleteRow
              selectedCheckboxes={selectedCheckboxes}
              getGuards={getGuards}
              setSelectedCheckboxes={setSelectedCheckboxes}
            />)}
          <button
            type="button"
            className="btn"
            style={{ marginRight: "10px", backgroundColor: "#154d32", color: "#fff" }}
            onClick={handleOpenAddModal}
          >
            <b> + Add Row </b>
          </button>
          <button className="btn"
            style={{ backgroundColor: "#154d32", color: "#fff" }}
            onClick={handleOpenAddInBulkModal}>
            <b> + Add in Bulk </b>
          </button>
          {openAddBulkModal && (
            <AddInBulk
              guards={guards}
              getGuards={getGuards}
              setOpenAddBulkModal={setOpenAddBulkModal}
              openAddBulkModal={openAddBulkModal} />
          )}
        </div>
        {openAddModal && (
          <AddRow
            getGuards={getGuards}
            guards={guards}
            setOpenAddModal={setOpenAddModal}
            openAddModal={openAddModal}
          />
        )}
      </div>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ whiteSpace: 'nowrap' }}>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, rowIndex) => (
                <TableRow
                  style={{ whiteSpace: 'nowrap' }}
                  key={rowIndex + (currentPage - 1) * itemsPerPage}
                  onMouseOver={() => handleRowHover(rowIndex)}
                  onMouseLeave={() => handleRowHover(null)}
                >
                  {columns.map((column, columnIndex) => (
                    <TableCell key={column.id}>
                      {column.format
                        ? columnIndex === 0
                          ? column.format(row.srNo)
                          : column.format(row[column.id])
                        : row[column.id]}
                      {column.id === "#" && (
                        <input
                          type="checkbox"
                          checked={isCheckboxSelected(row)}
                          onChange={(event) => handleCheckboxChange(event, row)}
                        />
                      )}
                      {column.id === "vendorName" &&
                        rowIndex === hoveredRowIndex && (
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => handleEdit(row)}
                          >
                            <EditIcon
                              style={{
                                color: "#496a5b",
                                background: "#d9ebe1",
                                padding: "5px 5px",
                                borderRadius: "10px",
                              }}
                            />
                          </IconButton>
                        )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Footer
          downloadXLSXData={downloadXLSXData}
          handleFilter={handleFilter}
          handleRefresh={handleRefresh}
          isFiltering={isFiltering}
          totalItems={totalItems}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      {selectedGuard && (
        <EditRow
          getGuards={getGuards}
          guards={guards}
          setGuards={setGuards}
          openEditModal={openEditModal}
          selectedGuard={selectedGuard}
          handleCloseEditModal={handleCloseEditModal}
        />
      )}
    </div>
  );
}

export default Guards;
