import React  from "react";
import axios from "axios";

const DeleteRow = ({ selectedCheckboxes, getGuards , setSelectedCheckboxes }) => {

  const handleDelete = async () => {
    try {
      const localToken = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      };

      if (selectedCheckboxes.length > 0) {
        const selectedIds = selectedCheckboxes
          .map((guard) => guard.id)
          .join(",");

        await axios.delete(
          `https://guards-apis.onrender.com/api/guards?guardIds=${selectedIds}`,
          config
        );
        await getGuards();

        setSelectedCheckboxes([]);
      } else {
        alert("Please select at least one guard to delete.");
      }
    } catch (error) {
      console.error("Error deleting guards:", error);
    }
    // Move getGuards inside the try-catch block to ensure it's called after handling the response
  };

  return (
    <button
      type="button"
      className="btn btn-danger"
      onClick={handleDelete}
      style={{ marginRight: "10px" }}
    >
      Delete
    </button>
  );
};

export default DeleteRow;
