import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

const EditRow = ({ setGuards, getGuards, guards, openEditModal, selectedGuard, handleCloseEditModal }) => {
  const [editedGuard, setEditedGuard] = useState(selectedGuard);

  useEffect(() => {
    setEditedGuard(selectedGuard);
  }, [selectedGuard]);

  const handleInputChange = (e, field) => {
    setEditedGuard((prevEditedGuard) => ({
      ...prevEditedGuard,
      [field]: e.target.value,
    }));
  };

  const handleSaveEdit = async () => {
    try {
      // Check for empty mandatory fields
      const mandatoryFields = [
        "srNo",
        "branchLatLong",
        "nameOfGuard",
        "guardWhatsapp",
        "aoName",
        "aoWhatsapp",
      ];

      const emptyFields = mandatoryFields.filter(
        (field) => !editedGuard[field]
      );

      if (emptyFields.length > 0) {
        alert(`Please fill in the following mandatory fields: ${emptyFields.join(", ")}`);
      } else {
        const localToken = localStorage.getItem("auth_token");
        const config = {
          headers: {
            Authorization: `Bearer ${localToken}`,
          },
        };
        // console.log("guards: ", {
        //   id: selectedGuard.id,
        //   srNo: selectedGuard.srNo,
        //   zone: selectedGuard.zone,
        //   branchCode: selectedGuard.branchCode,
        //   branchName: selectedGuard.branchName,
        //   branchLatLong: selectedGuard.branchLatLong,
        //   nameOfGuard: selectedGuard.nameOfGuard,
        //   guardWhatsapp: selectedGuard.guardWhatsapp,
        //   guardAge: selectedGuard.guardAge,
        //   aoName: selectedGuard.aoName,
        //   aoWhatsapp: selectedGuard.aoWhatsapp,
        //   vendorName: selectedGuard.vendorName,
        //   createdAt: selectedGuard.createdAt,
        //   updatedAt: selectedGuard.updatedAt,
        // });
        await axios.patch(
          `https://guards-apis.onrender.com/api/guards/${editedGuard.id}`,
          editedGuard,
          config
        );

        const updatedGuards = guards.map((guard) =>
          guard.srNo === editedGuard.srNo ? editedGuard : guard
        );

        window.location.reload();
        setGuards(updatedGuards);
        handleCloseEditModal();
      }
    } catch (error) {
      console.error("Error saving edit:", error);
      alert("Error saving edit. Please try again.");
    }
  };

  return (
    <Dialog open={openEditModal} onClose={handleCloseEditModal}>
      <DialogTitle style={{
        display: "flex", justifyContent: "center",
        color: "white",
        backgroundColor: "#154d32",
        marginBottom: "25px"
      }}>
        Edit Guard Information
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Sr No"
          variant="outlined"
          color="success"
          value={editedGuard.srNo}
          onChange={(e) => handleInputChange(e, "srNo")}
          style={{ margin: "5px" }}
        />
        <TextField
          label="Zone"
          variant="outlined"
          color="success"
          value={editedGuard.zone}
          onChange={(e) => handleInputChange(e, "zone")}
          style={{ margin: "5px" }}
        />
        <TextField
          label="Branch Code"
          variant="outlined"
          color="success"
          value={editedGuard.branchCode}
          onChange={(e) => handleInputChange(e, "branchCode")}
          style={{ margin: "5px" }}
        />
        <TextField
          label="Branch Name"
          variant="outlined"
          color="success"
          value={editedGuard.branchName}
          onChange={(e) => handleInputChange(e, "branchName")}
          style={{ margin: "5px" }}
        />
        <TextField
          label="Branch LatLong"
          variant="outlined"
          color="success"
          value={editedGuard.branchLatLong}
          onChange={(e) => handleInputChange(e, "branchLatLong")}
          style={{ margin: "5px" }}
        />
        <TextField
          label="Name of Guard"
          variant="outlined"
          color="success"
          value={editedGuard.nameOfGuard}
          onChange={(e) => handleInputChange(e, "nameOfGuard")}
          style={{ margin: "5px" }}
        />
        <TextField
          label="Guards Age"
          variant="outlined"
          color="success"
          value={editedGuard.guardAge}
          onChange={(e) => handleInputChange(e, "guardAge")}
          style={{ margin: "5px" }}
        />
        <TextField
          label="Guards WhatsApp"
          variant="outlined"
          color="success"
          value={editedGuard.guardWhatsapp}
          onChange={(e) => handleInputChange(e, "guardWhatsapp")}
          style={{ margin: "5px" }}
        />
        <TextField
          label="AoName"
          variant="outlined"
          color="success"
          value={editedGuard.aoName}
          onChange={(e) => handleInputChange(e, "aoName")}
          style={{ margin: "5px" }}
        />
        <TextField
          label="AoWhatsApp"
          variant="outlined"
          color="success"
          value={editedGuard.aoWhatsapp}
          onChange={(e) => handleInputChange(e, "aoWhatsapp")}
          style={{ margin: "5px" }}
        />
        <TextField
          label="Vendor Name"
          variant="outlined"
          color="success"
          value={editedGuard.vendorName}
          onChange={(e) => handleInputChange(e, "vendorName")}
          style={{ margin: "5px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseEditModal}
          style={{ color: "white", backgroundColor: "#154d32" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSaveEdit}
          style={{ color: "white", backgroundColor: "#154d32" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditRow;
