import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useUser } from '../../../App';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Switch
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { saveAs } from "file-saver";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ExcelJS from "exceljs";
import Footer from "../Footer/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "font-awesome/css/font-awesome.min.css";
import ErrorComponent from "../404/ErrorComponent";

ChartJS.register(ArcElement, Tooltip, Legend);


function InactiveGuards({ graphData, attendanceRecordsFromHome, showInactiveGuardsTab, setShowInactiveGuardsTab }) {
    const itemsPerPage = 100;

    const location = useLocation();
    const user = useUser();

    const { state } = location;
    const aoName = state?.aoName || "";
    const aoMobile = user?.email === "operations@radiantguards.com" ? state?.aoMobile || "" : "";

    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFiltering, setIsFiltering] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [attendanceRecords, setAttendanceRecords] = useState([]);
    const [graphDataForInactiveGuards, setGraphDataForInactiveGuards] = useState({
        inactive: 0,
        totalGuardCOunt: 0,
    });
    const [aoNameFilter, setAONameFilter] = useState("");
    const [isToggled, setIsToggled] = useState(false);

    async function getInactiveGuardsRecords() {
        try {
            const localToken = localStorage.getItem("auth_token");
            const config = {
                headers: {
                    Authorization: `Bearer ${localToken}`,
                },
            };
            setIsLoading(true);
            const { data } = await axios.get(
                ' https://guards-apis.onrender.com/api/attendance/inactive-guards',
                config
            );
            setIsLoading(false);
            console.log("data from inactive guards tab", data)
            let filteredRecords = data.filter(
                (record) =>
                    record.aoName.toLowerCase().includes(aoNameFilter.toLowerCase())
            );

            // If filteredRecords is empty and isToggled is true, mark all guards as present

            setGraphDataForInactiveGuards({
                inactive: data.length,
                totalGuardCOunt: attendanceRecordsFromHome.length,
            });

            setAttendanceRecords(filteredRecords);

        } catch (err) {
            console.error("error in .me: ", err, err.message);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getInactiveGuardsRecords();
    }, []);

    const isAoMobilePresent = () => {
        return attendanceRecords.some(record => record.aoWhatsapp === aoMobile);
    };

    const handleFilter = async () => {
        setIsFiltering(!isFiltering);

        // Filter the data based on AO Name only if the filter is not empty
        const filteredData = aoNameFilter
            ? attendanceRecords.filter((record) =>
                record.aoName.toLowerCase().includes(aoNameFilter.toLowerCase())
            )
            : attendanceRecords;

        // Update the filtered data state
        setFilteredData(filteredData);

        // Update the graph data state
        setGraphDataForInactiveGuards({
            inactive: filteredData.length,
            totalGuardCOunt: attendanceRecordsFromHome.length
        });

        setCurrentPage(1);
    };

    const filteredRecords = isFiltering ? filteredData : attendanceRecords;

    const startIndex = (currentPage - 1) * itemsPerPage;
    const paginatedData = filteredRecords.slice(startIndex, startIndex + itemsPerPage);
    const totalItems = filteredRecords.length;

    const handleRefresh = () => {
        console.log("handle refresh is calling")
    }

    const handleInactiveGuards = () => {
        setShowInactiveGuardsTab(!showInactiveGuardsTab);
    }

    const downloadXLSXData = async () => {
        let filename = aoNameFilter
            ? `${aoNameFilter} Inactive Guard Data.xlsx`
            : 'Inactive Guard Data.xlsx';

        const filteredRecords = isFiltering
            ? attendanceRecords.filter(
                (record) =>
                    record.aoName.toLowerCase().includes(aoNameFilter.toLowerCase())
            )
            : attendanceRecords;

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("GuardData");
        worksheet.columns = [
            { header: "Sr No.", key: "SrNo" },
            { header: "Zone", key: "Zone" },
            { header: "Branch Code", key: "BranchCode" },
            { header: "Branch Name", key: "BranchName" },
            { header: "State", key: "State" },
            { header: "Name of Guard", key: "NameOfGuard" },
            { header: "Guard's Mobile Number", key: "MobileNumber" },
            { header: "Guard's Age", key: "Age" },
            { header: "Ao Name", key: "AoName" },
            { header: "Ao Number", key: "AoNumber" },
            { header: "Vendor Name", key: "vendor" },
        ];

        // Add data to the worksheet (filteredData is an array of objects)
        worksheet.addRows(
            filteredRecords.map((record) => ({
                SrNo: record.srNo,
                Zone: record.zone,
                BranchCode: record.branchCode,
                BranchName: record.branchName,
                State: record.state,
                NameOfGuard: record.nameOfGuard,
                MobileNumber: record.guardWhatsapp,
                Age: record.guardAge,
                AoName: record.aoName,
                AoNumber: record.aoWhatsapp,
                vendor: record.vendorName,
            }))
        );

        // Generate the XLSX file as a Blob
        const blob = await workbook.xlsx.writeBuffer();

        // Create a Blob object and initiate the download
        const xlsxBlob = new Blob([blob], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(xlsxBlob, filename);
    };

    console.log("graphDataForInactiveGuards from line no 186", graphDataForInactiveGuards)
    const data = {
        labels: ["Inactive", "Total Number of Guards"],
        datasets: [
            {
                data: [graphDataForInactiveGuards.inactive, graphDataForInactiveGuards.totalGuardCOunt],
                backgroundColor: ["#3884f3", "#8b8c8e"],
            },
        ],
    };
    const options = {
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    generateLabels: function (chart) {
                        const data = chart.data;
                        const datasets = data.datasets;
                        console.log("dataset for inactive guards data from 205", datasets);
                        if (datasets.length) {
                            const dataset = datasets[0];
                            const absentValue = dataset.data[0];
                            const presentValue = dataset.data[1];

                            return [
                                {
                                    text: ` Inactive: ${absentValue}`,
                                    fillStyle: "#3884f3",
                                    hidden: false,
                                    index: 0,
                                    datasetIndex: 0,
                                },
                                {
                                    text: `Total No. Guards: ${presentValue}`,
                                    fillStyle: "#8b8c8e",
                                    hidden: false,
                                    index: 1,
                                    datasetIndex: 0,
                                },
                            ];
                        }
                        return [];
                    },
                },
            },
            tooltip: {
                enabled: false,
            },
        },
    };
    if (isLoading)
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress size="9rem" sx={{ color: "#154d32" }} />
            </Box>
        );

    if (user?.email === "operations@radiantguards.com" && !isAoMobilePresent()) {
        // Show "Go back" button if aoMobile is not present
        return (
            <div>
                <ErrorComponent />
            </div>
        );
    }
    return (
        <div className="border border-light rounded ml-3 mr-4 shadow-md">
            <div
                className="d-flex flex-column flex-lg-row ml-3"
                style={{ marginTop: "20px" }}
            >
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 600 }} size="small">
                        <TableHead>
                            <TableRow style={{ whiteSpace: 'nowrap', backgroundColor: '#154d32' }}>
                                <TableCell style={{ color: "#fff" }}>Name of Guard</TableCell>
                                <TableCell style={{ color: "#fff" }}>Branch Code</TableCell>
                                <TableCell style={{ color: "#fff" }}>Mobile Number</TableCell>
                                <TableCell style={{ color: "#fff" }}>Ao Name</TableCell>
                                <TableCell style={{ color: "#fff" }}>Ao Number</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((row, rowIndex) => {
                                return (
                                    <TableRow key={rowIndex}>
                                        <TableCell style={{ whiteSpace: 'nowrap' }}>{row.nameOfGuard}</TableCell>
                                        <TableCell>{row.branchCode}</TableCell>
                                        <TableCell>{row.guardWhatsapp}</TableCell>
                                        <TableCell style={{ whiteSpace: 'nowrap' }}>{row.aoName}</TableCell>
                                        <TableCell>{row.aoWhatsapp}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div className="chart-container shadow-md">
                    {(user?.email !== "operations@radiantguards.com" && user?.organization?.id !== "clvafmvaz09mnmr1tifr2prjf") && (
                        <div style={{ position: 'relative', width: '100%' }}>
                            <input
                                type="input"
                                placeholder="Search by AO Name"
                                style={{ width: "100%", marginBottom: "25px", paddingRight: "30px" }}
                                value={aoNameFilter}
                                onChange={(e) => setAONameFilter(e.target.value)}
                            />
                            <FontAwesomeIcon
                                icon={faSearch}
                                style={{
                                    position: "absolute",
                                    right: "0.4px",
                                    top: "26.8%",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                    backgroundColor: "#154d32",
                                    color: "#fff",
                                    padding: "6.8px 10px",
                                    borderTopRightRadius: "3px",
                                    borderBottomRightRadius: "1px",
                                }}
                                onClick={handleFilter}
                            />
                        </div>)}
                    <div
                        style={{
                            border: "1px solid rgb(240, 240, 240)",
                            borderRadius: "5px",
                        }}
                    >
                        <h3 className="pie-heading">Inactive Guards</h3>
                        <Pie
                            data={data}
                            options={options}
                            height={0}
                            width={0}
                            className="chart-container-pie"
                        />
                    </div>
                    <div
                        style={{
                            border: "1px solid rgb(240, 240, 240)",
                            borderRadius: "5px",
                            marginTop: "2%"
                        }}
                    >
                        <h3 className="pie-heading">Analytics</h3>
                        <Pie
                            height={0}
                            width={0}
                            className="chart-container-pie"
                            data={{
                                labels: ["Absent", "Present"],
                                datasets: [
                                    {
                                        data: [graphData.absent, graphData.present],
                                        backgroundColor: ["red", "green"],
                                    },
                                ],
                            }}
                            options={{
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: "bottom",
                                        labels: {
                                            generateLabels: function (chart) {
                                                const data = chart.data;
                                                const datasets = data.datasets;
                                                console.log("datasets from Home tab", datasets)
                                                if (datasets.length) {
                                                    const dataset = datasets[0];
                                                    const absentValue = dataset.data[0];
                                                    const presentValue = dataset.data[1];

                                                    return [
                                                        {
                                                            text: `Absent: ${absentValue}`,
                                                            fillStyle: "red",
                                                            hidden: false,
                                                            index: 0,
                                                            datasetIndex: 0,
                                                        },
                                                        {
                                                            text: `Present: ${presentValue}`,
                                                            fillStyle: "green",
                                                            hidden: false,
                                                            index: 1,
                                                            datasetIndex: 0,
                                                        },
                                                    ];
                                                }
                                                return [];
                                            },
                                        },
                                    },
                                    tooltip: {
                                        enabled: false,
                                    },
                                },
                            }}
                        />

                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "5%" }}>
                        <button type="button" class="btn btn-secondary" onClick={handleInactiveGuards}>Back To Home Tab</button>
                    </div>
                </div>
            </div>
            <div>
                <Footer
                    downloadXLSXData={downloadXLSXData}
                    handleFilter={handleFilter}
                    handleRefresh={handleRefresh}
                    isFiltering={isFiltering}
                    totalItems={totalItems}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </div>
    )
}

export default InactiveGuards;