import React, { useCallback, useEffect, useState, createContext, useContext } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Container from "@mui/material/Container";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import NavBar from "./Components/Layout/NavBar";
import ErrorComponent from "./Components/Routes/404/ErrorComponent";
import LoginPage from "./Components/Login/LoginPage";
import Home from "./Components/Routes/Home/Home";
import Guards from "./Components/Routes/Guards/Guards";
import ChangePassword from "./Components/Change-Password/ChangePassword";
import "./App.css";
import AoFilterPage from "./Components/Routes/AoFilter/AoFilterPage";
import AppointmentBooking from "./Components/Routes/AppointmentBooking/AppointmentBooking";
import BusinessNumber from "./Components/Routes/BusinessNumber/BusinessNumber";

// Create a context for the user
const UserContext = createContext();

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const authorize = useCallback(async () => {
    try {
      setIsLoading(true);

      const localToken = localStorage.getItem("auth_token");

      const config = {
        headers: {
          Authorization: `Bearer ${localToken}`,
        },
      };

      const response = await axios.get(
        "https://guards-apis.onrender.com/api/user/me",
        config
      );

      setUser(response.data);
      setIsLoggedIn(true);
    } catch (err) {
      console.error("error in .me: ", err, err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    authorize();
  }, [authorize]);

  useEffect(() => {
    if (user?.email === "operations@radiantguards.com") {
      navigate("/aofilter");
    }
    if (user?.organization?.id === "clsy51izu01wfhd270cihsj6n") {
      navigate("/businessNumber");
    }
  }, [user]);

  if (isLoading) return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', }}>
      <CircularProgress size="9rem" sx={{ color: '#154d32' }} />
    </Box>
  );
  return (
    <UserContext.Provider value={user}>
      {isLoggedIn ? (
        <>
          {location.pathname !== '/change-password' && location.pathname !== '/aofilter' && location.pathname !== '/businessNumber' && <NavBar />}
          <Container maxWidth="xl" style={{ marginTop: "80px" }}>
            <Routes>
              {user.organization.id === 'clsy51izu01wfhd270cihsj6n' ? (
                <Route path="/" element={<AppointmentBooking />} />
              ) : (
                <Route path="/" element={<Home />} />
              )}
              {(user?.email !== "operations@radiantguards.com" && user?.organization?.id !== "clsy51izu01wfhd270cihsj6n" && user?.email !== 'glcontrolroomthane@iifl.com') && (
                <Route path="/guards" element={<Guards />} />
              )}
              <Route path="/change-password" element={<ChangePassword />} />
              {(user?.organization?.id !== 'clsy51izu01wfhd270cihsj6n' && user?.organization?.id !== "clvafmvaz09mnmr1tifr2prjf" && user?.email !== 'glcontrolroomthane@iifl.com') && <Route path="/aofilter" element={<AoFilterPage />} />}
              {user?.organization?.id === 'clsy51izu01wfhd270cihsj6n' && <Route path="/businessNumber" element={<BusinessNumber />} />}
              <Route path="*" element={<ErrorComponent />} />
            </Routes>
          </Container>
        </>
      ) : (
        <LoginPage />
      )}
    </UserContext.Provider>
  );

}

// Create a custom hook to consume the UserContext
export const useUser = () => useContext(UserContext);
export default App;